import React from 'react';
import './App.css';
import { useTranslation } from "react-i18next";

function App() {
  const { t } = useTranslation();

  return (
    <div className="App">
      <header className="App-header">
        <p>
	 {t("mako-sama")}<br/>
	 {t("wedding-congrats")}
        </p>
      </header>
    </div>
  );
}

export default App;
